import Router from "next/router";
import {
    APP_ROUTE_KEYS,
    NEXT_ROUTE_PATHNAMES,
} from "repoV2/constants/urls&routing/routing";
import {
    MS_CLARITY_EVENTS_MAP,
    MS_CLARITY_EVENT_TYPE,
} from "./MSClarity.constants";
import { ANALYTICS_EVENTS } from "../../Analytics.constants";

export const callMSClarity = (eventType: string) => {
    if (
        !(
            typeof window !== "undefined" &&
            typeof window.clarity === "function" &&
            Router.router
        )
    )
        return;

    const { pathname } = Router.router;
    let eventName;
    switch (eventType) {
        case ANALYTICS_EVENTS.LEAD:
        case ANALYTICS_EVENTS.ADD_PAYMENT_INFO: {
            if (
                NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.CHECKOUT_LISTING] ===
                pathname
            ) {
                eventName = MS_CLARITY_EVENTS_MAP[eventType];
            }
            break;
        }
        case ANALYTICS_EVENTS.SALE_PAGE_REGISTER_CTA_CLICK: {
            if (NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.SALE] === pathname) {
                eventName = MS_CLARITY_EVENTS_MAP[eventType];
            }
            break;
        }
        default:
    }

    if (eventName) {
        window.clarity(MS_CLARITY_EVENT_TYPE.EVENT, eventName);
    }
};
