/**
 * Retrieves the value of a cookie by its key.
 *
 * This function searches the document's cookies for a cookie with the specified key
 * and returns its value if found. If the cookie is not found, it returns undefined.
 *
 * @param {string} key - The key of the cookie to retrieve.
 * @returns {string | undefined} - The value of the cookie if found, otherwise undefined.
 */
export const getCookieValue = (key: string) =>
    document.cookie
        .split("; ")
        .find(row => row.startsWith(`${key}=`))
        ?.split("=")[1];

/**
 * Sets a cookie with the specified key, value, and expiration days.
 *
 * This function creates a new cookie or updates an existing cookie with the specified key
 * and value. The cookie will expire after the specified number of days. If no days are provided,
 * the cookie will be a session cookie and will be deleted when the browser is closed.
 *
 * @param {Object} params - The parameters for setting the cookie.
 * @param {string} params.key - The key of the cookie.
 * @param {string} params.value - The value of the cookie.
 * @param {number} [params.days] - The number of days until the cookie expires. If not provided, the cookie will be a session cookie.
 */
export const setCookie = ({
    key,
    value,
    days,
}: {
    key: string;
    value: string;
    days: number;
}) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${key}=${value || ""}${expires}; path=/`;
};
