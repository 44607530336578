// if: lines of code > 300
// then: breakdown this file and move to repoV2/features/Analytics/utils folder

import Router from "next/router";
import { callPostExlyAnalytics } from "repoV2/utils/common/analytics/exlyPostAnalytics/exlyPostAnalytics";
import { isRequestSuccessful } from "repoV2/utils/common/api/api";
import { callApi } from "repoV2/utils/common/api/callApi";
import { logError } from "repoV2/utils/common/error/error";
import { isBrowser } from "repoV2/utils/common/render/window";
import { getLoggedInUserDetails } from "repoV2/utils/user&auth&login/loggedInUserDetails";
import {
    APP_ROUTE_KEYS,
    NEXT_ROUTE_PATHNAMES,
} from "repoV2/constants/urls&routing/routing";
import { appendURLSearchParams } from "@my-scoot/exly-react-component-lib";
import { getSHA256Hash } from "../modules/Hashing/modules/SHA256/SHA256";
import { hashObjectValues } from "../modules/Hashing/utils/Hashing.utils";
import { getCookieValue } from "../modules/Storage/modules/Cookies/utils/Cookies.utils";
import {
    ANALYTICS_APIS,
    ANALYTICS_EVENTS,
    ANALYTICS_META_EVENTS_MAP,
} from "./Analytics.constants";
import { IAnalytics } from "./Analytics.interfaces";
import { callGtag } from "./modules/GoogleTagManager/GoogleTagManager.utils";
import { META_ANALYTICS_COOKIE_KEYS } from "./modules/MetaPixel/MetaPixel.constants";
import {
    callPostMetaEvent,
    getFbcCookie,
    normalizeMetaCustomerInfoParam,
    normalizeMetaCustomerNames,
    normalizeMetaCustomerPhone,
} from "./modules/MetaPixel/MetaPixel.utils";
import { callMSClarity } from "./modules/MSClarity/MSClarity.utils";

// analytics/fb/conversion/post
export const postAnalytics: IAnalytics.IPostAnalytics = async (
    eventName,
    eventData = {}
) => {
    if (!eventName) return;

    const { isListingPurchasePage, listingUuid } = getListingUuidFromRouter();

    const { isSalePage, salePageSlug } = getSalePageSlugFromRouter();

    const {
        // common event data - start
        currency,
        value,
        // common event data - end

        // META event data - start
        sub_domain,
        event_id,
        event_source_url,
        city,
        content_name,
        country,
        order_id,
        postal_code,
        content_category,
        num_items,
        user_data: userData,
        // META event data - end

        // GTM event data - start
        // items,
        // transaction_id,
        // GTM event data - end

        // exly analytics data - start
        event_meta,
        // exly analytics data - end
    } = eventData;

    /* Meta call start */

    // TODO:
    // - rename existing callPostMetaEvent to callPostMetaEventBase
    // - move complete Meta block inside the newly created callPostMetaEvent

    const metaEventName = ANALYTICS_META_EVENTS_MAP[eventName];

    if (metaEventName) {
        // e.g, `ANALYTICS_EVENTS.SALE_PAGE_REGISTER_CTA_CLICK` is not tracked for meta
        // `metaEventName` will be undefined in that case

        const {
            em: email,
            ph: phone,
            name: customerName,
            ...restUserData
        } = userData ?? {};
        const customerUuid = getLoggedInUserDetails()?.uuid;
        const { firstNames, lastNames } =
            normalizeMetaCustomerNames(customerName);

        const fbc = getFbcCookie();
        const fbp = getCookieValue(META_ANALYTICS_COOKIE_KEYS.fbp);

        const metaEventData = {
            sub_domain,
            event_name: metaEventName,
            event_id,
            event_source_url,
            custom_data: {
                city,
                content_name,
                country,
                order_id,
                postal_code,
                content_category,
                num_items,
                currency,
                value,
            },
            user_data: {
                ...restUserData,
                fbc,
                fbp,
            },
            ...(listingUuid &&
                isListingPurchasePage && { listing_uuid: listingUuid }),
            ...(salePageSlug && isSalePage && { sale_page_slug: salePageSlug }),
        };

        // add customer info hashed params
        try {
            metaEventData.user_data = {
                ...metaEventData.user_data,
                ...(await hashObjectValues(
                    {
                        em: normalizeMetaCustomerInfoParam(email),
                        ph: normalizeMetaCustomerPhone(
                            normalizeMetaCustomerInfoParam(phone)
                        ),
                        fn: normalizeMetaCustomerInfoParam(firstNames),
                        ln: normalizeMetaCustomerInfoParam(lastNames),
                        external_id: customerUuid,
                    },
                    getSHA256Hash
                )),
            };
        } catch (error) {
            logError({
                error,
                customErrorMessage: "error hashing customer info params",
                occuredAt:
                    "repoV2/features/Common/Analytics/Analytics.utils.ts",
                severity: "low",
                when: "calling hashObjectValues",
            });
        }

        const metaEventCommonPayload = {
            sub_domain: metaEventData.sub_domain,
            event_name: metaEventData.event_name,
            user_data: metaEventData.user_data,
            ...(listingUuid && { listing_uuid: listingUuid }),
            ...(salePageSlug && isSalePage && { sale_page_slug: salePageSlug }),
        };

        switch (eventName) {
            case ANALYTICS_EVENTS.CONTACT:
            case ANALYTICS_EVENTS.LEAD:
                callPostMetaEvent({
                    ...metaEventCommonPayload,
                    custom_data: { content_name, content_category },
                });
                break;
            case ANALYTICS_EVENTS.ADD_PAYMENT_INFO:
                callPostMetaEvent(metaEventCommonPayload);
                break;
            case ANALYTICS_EVENTS.INITIATE_CHECKOUT:
                callPostMetaEvent({
                    ...metaEventCommonPayload,
                    custom_data: {
                        num_items,
                        currency,
                        value,
                    },
                });
                break;
            default:
                callPostMetaEvent(metaEventData);
                break;
        }
    }
    /* meta call end */

    /* MS Clarity call start */
    callMSClarity(eventName);
    /* MS Clarity call end */

    /* ExlyAnalytics call start */
    // POST_EXLY_ANALYTICS_API_URL
    if (eventName === ANALYTICS_EVENTS.PAGE_VIEW) {
        callPostExlyAnalytics({
            screen: "mypageScreen",
            funnel: "mypage",
            flow: "mypage",
            event_name: "completelyLoaded",
            action: "view",
            event_meta,
        });
    }
    /* ExlyAnalytics call end */

    /* gtag call start */
    callGtag(eventName, eventData);
    /* gtag call end */
};

/**
 * triggerPageViewAnalyticsEvent - This util triggers the PAGE VIEW post analytics event
 * @param hostName string - creator domain
 * @param eventMeta string - listing uuid
 */
export function triggerPageViewAnalyticsEvent({
    hostName,
    eventMeta,
}: {
    hostName: string;
    eventMeta?: string;
}) {
    if (isBrowser()) {
        postAnalytics(ANALYTICS_EVENTS.PAGE_VIEW, {
            sub_domain: hostName,
            event_source_url: window.location.href,
            event_meta: eventMeta,
        });
    }
}

/**
 * Fetches analytics configuration based on the provided host name.
 * @param {string} hostName - The host name for which analytics configuration is required.
 * @returns {Promise<Object>} A promise that resolves to the analytics configuration.
 */
export const fetchAnalyticsConfig = ({
    hostName,
}: {
    hostName: string;
}): Promise<IAnalytics.IConfig> =>
    new Promise((resolve, reject) => {
        const { isListingPurchasePage, listingUuid } =
            getListingUuidFromRouter();
        const { isSalePage, salePageSlug } = getSalePageSlugFromRouter();
        callApi({
            url: appendURLSearchParams(
                `${ANALYTICS_APIS.GET_ANALYTICS_CONFIG}/${hostName}`,
                {
                    ...(listingUuid &&
                        isListingPurchasePage && { listing_uuid: listingUuid }),
                    ...(salePageSlug &&
                        isSalePage && { sale_page_slug: salePageSlug }),
                }
            ),
            extraOptions: {
                appendExlyBackendHeaders: true,
            },
        })
            .then((apiResponse: any) => {
                const { status, data, message } = apiResponse?.data || {};
                if (!isRequestSuccessful(status)) throw new Error(message);
                resolve(data);
            })
            .catch(reject);
    });

/**
 * Retrieves the listing UUID and determines if the current page is a listing purchase-related page.
 * @returns An object containing:
 * - `isListingPurchasePage` (boolean): Whether the current page is related to listing purchase.
 * - `listingUuid` (string | undefined): The UUID of the listing or event if available.
 */
const getListingUuidFromRouter = () => {
    if (!isBrowser())
        return { isListingPurchasePage: false, listingUuid: undefined };
    const pathName = Router.router?.pathname || "";
    const listingUuid =
        Router.router?.query?.eventId || Router.router?.query?.listingSlug;

    const isListingPurchasePage = [
        NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.CHECKOUT_LISTING],
        NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.EVENT],
        NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.THANKYOU_PURCHASE],
        NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.THANKYOU_OPTIN],
    ].includes(pathName);

    return { isListingPurchasePage, listingUuid };
};

/**
 * Determines if the current page is a sale page and retrieves the sale page slug.
 * @returns An object containing:
 * - `isSalePage` (boolean): Whether the current page is a sale page.
 * - `salePageSlug` (string | undefined): The slug of the sale page if available.
 */
const getSalePageSlugFromRouter = () => {
    if (!isBrowser()) return { isSalePage: false, salePageSlug: undefined };
    const pathName = Router.router?.pathname || "";
    const isSalePage = pathName === NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.SALE];
    const salePageSlug = Router.router?.query?.slug;

    return { isSalePage, salePageSlug };
};
