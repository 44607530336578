import { logError } from "repoV2/utils/common/error/error";
import { isBrowser } from "repoV2/utils/common/render/window";
import { GOOGLE_TAG_MANAGER_EVENT_TYPE } from "./GoogleTagManager.constants";
import { ANALYTICS_GTM_EVENTS_MAP } from "../../Analytics.constants";
import { IAnalytics } from "../../Analytics.interfaces";

export const callGtag = (
    eventType: string,
    eventData: IAnalytics.IEventData
) => {
    const gtmEventType = ANALYTICS_GTM_EVENTS_MAP[eventType];

    // e.g, `ANALYTICS_EVENTS.SALE_PAGE_REGISTER_CTA_CLICK` is not tracked for GTM
    // `gtmEventType` will be undefined in that case
    if (!gtmEventType) return;

    const { currency, value, items, transaction_id } = eventData;

    const gtmEventData = {
        currency,
        value,
        items,
        transaction_id,
    };

    // if: _callGtagBase is not called successfully
    // then: we will retry calling it after every 1 second
    if (!_callGtagBase(gtmEventType, gtmEventData)) {
        let retries = 1;
        const intervalId = setInterval(() => {
            // if: number of retries > 5
            // then: there must be some issue, log it.
            if (retries > 5) {
                clearInterval(intervalId);
                logError({
                    error: "gtag not defined",
                    occuredAt: "analytics.ts",
                    when: "calling Gtag",
                    extraErrorData: {
                        location: "callGtag",
                        isBrowser: isBrowser(),
                        // @ts-ignore
                        typeOfGtag: typeof window?.gtag,
                        gtmEventType,
                        gtmEventData,
                    },
                });
                return;
            }

            // if: the call is successful
            // then: clear the interval
            if (_callGtagBase(gtmEventType, gtmEventData)) {
                clearInterval(intervalId);
                return;
            }

            retries += 1;
        }, 1000);
    }
};

/**
 * base function to call gtag
 * @returns true if gtag was called successfully
 */
const _callGtagBase = (
    gtmEventType: string,
    gtmEventData: {
        currency: string | undefined;
        value: number | undefined;
        items:
            | {
                  item_id: string;
                  item_name: string;
              }[]
            | undefined;
        transaction_id: string | undefined;
    }
) => {
    if (isBrowser() && typeof window?.gtag === "function") {
        window.gtag(
            GOOGLE_TAG_MANAGER_EVENT_TYPE.EVENT,
            gtmEventType,
            gtmEventData
        );
        return true;
    }
    return false;
};
