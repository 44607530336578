/**
 * Computes the SHA-256 hash of a given input string.
 *
 * This function takes a string input, encodes it as UTF-8, and computes its SHA-256 hash
 * using the SubtleCrypto interface. It returns the resulting hash as a hexadecimal string.
 *
 * @param {string} input - The input string to hash.
 * @returns {Promise<string>} - A promise that resolves to the hexadecimal representation of the SHA-256 hash.
 *
 * ref: https://remarkablemark.medium.com/how-to-generate-a-sha-256-hash-with-javascript-d3b2696382fd
 */

export const getSHA256Hash = async (input: string) => {
    const utf8 = new TextEncoder().encode(input);
    const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(bytes => bytes.toString(16).padStart(2, "0")).join("");
};
