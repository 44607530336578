/**
 * Hashes the values of an input object using a specified hash function.
 *
 * This function takes an object with string values, arrays of strings, or undefined values,
 * hashes each value using a provided asynchronous hash function, and returns a new object
 * with the hashed values. If a value is undefined or an error occurs during hashing,
 * the corresponding key's value will be set to undefined.
 *
 * @param {Object} inputObject - The input object with string values, arrays of strings, or undefined values to hash.
 * @param {Function} hashFunction - An asynchronous function that takes a string and returns a promise that resolves to a hashed string.
 * @returns {Promise<Object>} - A promise that resolves to a new object with the same keys as the input object and hashed values.
 */
export const hashObjectValues = async (
    inputObject: { [key: string]: string | string[] | undefined },
    hashFunction: (input: string) => Promise<string>
): Promise<{ [key: string]: string | string[] | undefined }> => {
    const entries = Object.entries(inputObject);

    const hashedEntries = await Promise.all(
        entries.map(async ([key, value]) => {
            try {
                if (value === undefined) {
                    return [key, undefined];
                } else if (Array.isArray(value)) {
                    const hashedArray = await Promise.all(
                        value.map(async item => {
                            try {
                                return await hashFunction(item);
                            } catch (error) {
                                return undefined;
                            }
                        })
                    );
                    return [
                        key,
                        hashedArray.filter(item => item !== undefined),
                    ];
                } else {
                    const hashedValue = await hashFunction(value);
                    return [key, hashedValue];
                }
            } catch (error) {
                return [key, undefined];
            }
        })
    );

    return Object.fromEntries(hashedEntries);
};
